import React from 'react';
import '../styles/About.css';

function About() {
  return (
    <section id="about" className="resume-container">
      <h2>About Me</h2>
      <p>My name is Carlos Barros, and I am a software developer with 2 years of dedicated experience in the industry. I specialize in full-stack development, with hands-on expertise in C#, TypeScript, JavaScript, and Python. My background includes roles such as Associate Software Engineer and internships that have enriched my skills in both front-end and back-end technologies. I hold a Computer Programming Diploma from Conestoga College and a Bachelor’s in Business Administration from Universidade Mauricio de Nassau. My career is marked by a strong ability to tackle technical challenges and deliver effective solutions.</p>

      <p>Some of my projects are shared on GitHub: <a href="https://github.com/carlosmsbf">https://github.com/carlosmsbf</a>.</p>
    
    </section>
  );
}

export default About;
