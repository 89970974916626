import React from 'react';
import '../../styles/Article.css';

function Article03() {
  return (
    <section id="article" className="resume-container">
          <div>
          <article class="article-container">
            <section>
            <h1 class="article-title">When Not to Use Async/Await in C#: A Practical Guide</h1>
            </section>
            <p className='date-label'>Date: 2024/Oct/07 </p>

            <section class="article-body">
                <h2>The Pitfalls of Using Async/Await Everywhere</h2>
                <p>While async/await is incredibly useful for I/O-bound tasks like network calls or file operations, it isn't a one-size-fits-all solution. Overusing async/await can degrade performance and introduce bugs.</p>

                <h2>When Not to Use Async/Await</h2>
                
                <h3>1. CPU-Bound Tasks</h3>
                <p>Async programming offers no advantage for CPU-bound tasks, such as data processing or mathematical calculations, as it introduces unnecessary overhead. Example:</p>

                <pre><code>{`
                public async Task int CalculateSumAsync(int[] numbers)
                {
                    return await Task.Run(() => numbers.Sum());
                }
                `}</code></pre>

                <h3>2. Short Synchronous Code</h3>
                <p>For short, fast operations, the overhead of async/await isn't justified. Example:</p>

                <pre><code>{`
                public async Task int GetUserCountAsync()
                {
                    using(var context = new DbContext())
                    {
                        return await context.Users.CountAsync();
                    }
                }
                `}</code></pre>

                <h3>3. Deep Call Stacks</h3>
                <p>Async chains can complicate exception handling, propagating errors across multiple layers of async methods.</p>

                <h3>4. Fire-and-Forget Methods</h3>
                <p>While possible, fire-and-forget methods with <code>async void</code> should be avoided due to lack of error handling and task monitoring.</p>

                <h3>5. Tight Loops with Limited Parallelism</h3>
                <p>Loops that await each iteration can become bottlenecks. Managing concurrency with <code>Task.WhenAll()</code> is more efficient.</p>

                <h2>Real-World Example</h2>
                <p>At one of my previous jobs, overuse of async/await led to increased bugs, slower performance, and harder debugging. Cascading failures occurred due to unhandled async operations, and unnecessary overhead slowed down the system.</p>

                <h2>What Should We Have Done?</h2>
                <ul>
                    <li><strong>Limit async/await to I/O-bound tasks:</strong> Restrict async to operations like API calls, file processing, and database operations.</li>
                    <li><strong>Keep CPU-bound tasks synchronous:</strong> Avoid async for calculations or small, fast operations.</li>
                    <li><strong>Handle exceptions properly:</strong> Use try/catch blocks to ensure async failures are handled gracefully.</li>
                    <li><strong>Control parallelism:</strong> Use <code>Task.WhenAll()</code> for running multiple async tasks in parallel.</li>
                </ul>
                <pre><code>{`
                public async Task FetchDataFromApisAsync()
                {
                    var api1Task = FetchDataFromApi1Async();
                    var api2Task = FetchDataFromApi2Async();

                    await Task.WhenAll(api1Task, api2Task);
                }
                `}</code></pre>
                <h2>Smart Strategies for Implementing Async/Await</h2>
                <ul>
                    <li><strong>Use for I/O-bound tasks:</strong> Async programming excels at non-blocking network requests, file I/O, and database calls.</li>
                    <li><strong>Avoid for CPU-bound tasks:</strong> Heavy calculations don't benefit from async.</li>
                    <li><strong>Be mindful of deep async chains:</strong> Minimize chaining to reduce error propagation.</li>
                    <li><strong>Handle exceptions gracefully:</strong> Always catch exceptions in async methods to avoid cascading failures.</li>
                    <li><strong>Avoid async void:</strong> Prefer returning <code>Task</code> to manage errors properly.</li>
                </ul>

                <h2>Conclusion: Strategic Use of Async/Await</h2>
                <p>Async/await is a powerful tool but should be used strategically. Overusing async can degrade performance and increase bugs. The key is to use async/await only when necessary to optimize performance without adding unnecessary complexity.</p>
        </section>
        </article>
        </div>
        </section>
  );
}

export default Article03;
