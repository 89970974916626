import React from 'react';

function Education() {
  return (
<section>
      <h2>Education</h2>
      <div>
        <h3> 2020 - 2022 | Computer Programming Diploma  </h3>
        <p> Conestoga College | Waterloo , Canada</p>
        <ul>
        </ul>
      </div>
      <div>
        <h3> 2009 - 2013 | Business Administration Bachelor </h3>
        <p> Universidade Mauricio de Nassau | Recife , Brazil</p>
        <ul>
        </ul>
      </div>
</section>
  );}

  export default Education;