// DarkTheme.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-regular-svg-icons';
import '../styles/DarkTheme.css'; // Import any specific styles for the theme toggle

function DarkTheme() {
  const [theme, setTheme] = useState(() => {
    // Retrieve the saved theme from localStorage, default to 'light'
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'light';
  });

  useEffect(() => {
    // Apply the theme to the document
    document.documentElement.setAttribute('data-theme', theme);
    // Save the theme to localStorage
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
<button 
  onClick={toggleTheme} 
  className={`theme-toggle ${theme}`} // Check if this is properly setting 'light' or 'dark' class
>
  <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun} className="icon" />
</button>
  );
}

export default DarkTheme;
