import React from 'react';

function Skills() {
  return (
    <section>
      <h2>Technical Skills</h2>
      <ul>
        <li>Programming Languages: C#, Python, TypeScript, JavaScript, SQL</li>
        <li>Web Development: ASP.NET, Bootstrap, React, Angular, Node.js, Express.js</li>
        <li>Databases: SQL Server, MongoDB, SQLite, Firebase</li>
        <li>Tools & Frameworks: Docker, RESTful APIs, NUnit, Selenium, Git</li>
      </ul>
    </section>
  );
}

export default Skills;
