import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Resume from './pages/Resume';
import Blog from './pages/Blog';
import Article01 from './pages/Article/OOP';
import Article02 from './pages/Article/async_await';
import Article03 from './pages/Article/when_to_use_aa';
import Article04 from './pages/Article/log_alerts';
import About from './pages/About';
import './App.css';

function App() {

  return (
    
    <Router>
      <div className="App">
        <Header />
        <Routes>

          <Route path="/resume" element={
            <section id="resume">
              <Resume/>
            </section>
          } />
          
          <Route path="/portfolio"/>
          
          <Route path="/about" element={<About />} />

          <Route path="/blog" element={ <Blog/>} />

          <Route path="/oop_article" element={ <Article01/>} />

          <Route path="/aa_article" element={ <Article02/>} />

          <Route path="/when_to_use_aa_article" element={ <Article03/>} />

          <Route path="/log_alerts" element={ <Article04/>} />

          <Route path="/" element={<About />} />

        </Routes>

      </div>
    </Router>
  );
}

export default App;
