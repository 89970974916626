import React from 'react';

function Projects() {
  return (
    <section>
      <h2>Projects</h2>
      <div>
        <h3>2022 | Capstone Project - Supply Chain Inventory Management System</h3>
        <p>Conestoga College</p>
        <ul>
          <li>Developed in C#, ASP.NET MVC CORE, JavaScript, Bootstrap, HTML5, CSS3, and SQL Server.</li>
          <li>Created an Inventory Control System for registering product transactions.</li>
        </ul>
      </div>
    </section>
  );
}

export default Projects;
