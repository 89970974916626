import React from 'react';

function Experience() {
  return (
    <section>
      <h2>Professional Experience</h2>
      <div>
        <h3>Associate Software Engineer (Remote) - Derivative Path</h3>
        <p>2023 - September 2024 | Kitchener, Canada</p>
        <ul>
          <li>Troubleshot and resolved system bugs across both Front-End and Back-End, improving system stability and user experience.</li>
          <li>Developed features and tests based on client’s needs.</li>
          <li>Developed SQL queries to efficiently extract, insert, and update records in the database, ensuring data accuracy and performance.</li>
          <li>Developed APIs using RabbitMQ, Docker, and RESTful services.</li>
        </ul>
      </div>
      <div>
        <h3>Intern (Remote) - Derivative Path</h3>
        <p>2022 - 2023 | Kitchener, Canada</p>
        <ul>
          <li>Achieved a goal with 3 weeks instead of 3 months to extract data from an unopen FPML file.</li>
          <li>Troubleshot and resolved Back-End bugs, improving system functionality.</li>
          <li>Extracted and analyzed data from SQL Server to generate comprehensive reports.</li>
        </ul>
      </div>
      <div>
        <h3>Supply Chain Supervisor - BRK Ambiental</h3>
        <p>2014 - 2019 | Recife, Brazil</p>
        <ul>
          <li>Led a team of 6–15 people and managed inventory for over 15,200 pieces of equipment.</li>
          <li>Implemented a purchase order plan that reduced expenses by 30%.</li>
          <li>Improved the operational efficiency of the purchase department by 70%.</li>
        </ul>
      </div>
      <div>
        <h3>Finance Analyst - Construtora Norberto Odebrecht</h3>
        <p>2013 - 2014 | Recife, Brazil</p>
        <ul>
          <li>Managed financial operations for a construction company with 700 employees, handling accounts and financial data.</li>
          <li>Developed and analyzed financial reports, reviewing daily financial data for accuracy and insights.</li>
          <li>Oversaw operational activities such as cash flow, refunds, supplier payments, and transfers.</li>
          <li>Implemented a streamlined payment process for suppliers, refunds, and transfers, increasing productivity by 25%.</li>
        </ul>
      </div>
    </section>
  );
}

export default Experience;
