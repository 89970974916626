import React from 'react';
import Summary from './Resume/Summary';
import Skills from './Resume/Skills';
import Experience from './Resume/Experience';
import Projects from './Resume/Projects';
import Education from './Resume/Education';
import DarkTheme from '../components/DarkTheme'; 
import '../styles/Resume.css';

function Resume() {
  return (
    <div className="resume-container">
              <DarkTheme/>
              <Summary />
              <Skills />
              <Experience />
              <Projects />
              <Education/>
    </div>
  );
}

export default Resume;
