import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Blog.css';
import '../styles/DarkTheme.css'; // Import any specific styles for the theme toggle

const articles = [
  { id: 1, 
    title: "Smart Log Alerts in C#: Strategies for Enhanced Application Stability", 
    date: "2024/Oct/29", 
    description: "This article explores the importance of log monitoring in C# applications, highlighting how effective log alerts can improve system reliability, expedite debugging, optimize performance, and enhance security. Using Serilog, we demonstrate how to implement log levels—Verbose, Debug, Information, Warning, Error, and Fatal—to monitor application health in real time. By setting targeted alerts, developers can proactively address potential issues, ensuring the application remains stable and responsive while delivering a seamless experience to end users.", 
    link: "/log_alerts" },
  { id: 2, 
    title: "When Not to Use Async/Await in C#: A Practical Guide", 
    date: "2024/Oct/07", 
    description: "While async/await is a powerful feature for improving the performance of I/O-bound tasks in C#, there are cases where its overuse can negatively impact your application's efficiency and maintainability. In this article, we dive into real-world scenarios where using async/await is not advisable, including CPU-bound tasks, short operations, and deep call stacks. Learn from practical examples and avoid common pitfalls to maintain performance and keep your codebase clean and easy to debug.", 
    link: "/when_to_use_aa_article" },
  { id: 3, 
    title: "Mastering Asynchronous Programming in C#", 
    date: "2024/Sept/30", 
    description: "Asynchronous programming is crucial for building responsive and scalable C# applications. This article breaks down how C#'s async/await pattern simplifies asynchronous tasks like file I/O and network calls, leading to better performance and maintainability. Explore how Task management in the .NET framework allows you to handle multiple operations concurrently, and learn best practices for ensuring smooth, non-blocking code execution. Whether you're new to async or looking to deepen your understanding, this guide covers everything from real-world use cases to essential async programming tips.", 
    link: "/aa_article" },
  { id: 4, title: "Object-Oriented Programming (OOP) in C# and JavaScript: A Comparative Guide", 
    date: "2024/Sept/23", 
    description: "This article explores the key principles of Object-Oriented Programming (OOP) in C# and JavaScript, comparing their approaches to classes, inheritance, encapsulation, and polymorphism. It highlights the differences between C#'s class-based, statically typed system and JavaScript's prototype-based, dynamically typed flexibility. With practical examples and side-by-side code snippets, the guide helps developers understand how each language implements OOP, offering insights to choose the right language for specific project needs.", 
    link: "/oop_article" },
];

function Blog() {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 3;
  const totalPages = Math.ceil(articles.length / articlesPerPage);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };



  return (
    <section id="about" className="resume-container">
      {currentArticles.map(article => (
        <div className="article-block" key={article.id}>
          <Link to={article.link}>
            <div className="article-title">
              <h2>{article.title}</h2>
            </div>
            <p className='date-label'>Date: {article.date}</p>
            <div className="article-description">
              <p>{article.description}</p>
            </div>
          </Link>
        </div>
      ))}

      <div className="pagination">
        <button  onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button 
            key={index + 1} 
            onClick={() => goToPage(index + 1)} 
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
        <button  className="filter-button"  onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
      </div>
    </section>
  );
}

export default Blog;
