import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope} from '@fortawesome/free-regular-svg-icons';
import '../styles/Header.css';
import DarkTheme from '../components/DarkTheme';

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        {/* Container for photo and other elements */}
        <div className="profile-container">
        <Link to="/about"><div className="profile-photo"></div></Link> {/* Use as background image */}
          <div className="half-circle left-half"></div>
          <div className="half-circle right-half"></div>

          <div className="social-icons">
          {/* GitHub Icon */}
          <a href="https://github.com/carlosmsbf" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} className="social-icon" />
          </a>
          {/* LinkedIn Icon */}
          <a href="https://www.linkedin.com/in/carlos-barros-6a63901a0/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
          </a>
           {/* Email Icon */}
           <a href="mailto:your-email@example.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faEnvelope} className="social-icon" />
          </a>
        </div>

        </div>



        <h1>Carlos Barros | Software Engineer</h1>
        <nav>
          <ul>
            {/* <li><Link to="/portfolio">Portfolio</Link></li>*/}
            <li><Link to="/blog">Blog</Link></li> 
            <li><Link to="/resume">Resume</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><DarkTheme /></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}


export default Header;
