import React from 'react';

function Summary() {
  return (
    <section>
      <h2>Summary</h2>
      <p>
        5 years in Software and Web Development using C#, TypeScript, JavaScript, and Python. 2 years of Software Engineer experience. Extensive leadership experience, technical expertise, and problem-solving skills across various domains.
      </p>
    </section>
  );
}

export default Summary;
