import React from 'react';
import '../../styles/Article.css';

function Article01() {
  return (
    <section id="article" className="resume-container">
          <div>
            <article>

            <section >
                <h1 className="article-title" >Object-Oriented Programming (OOP) in C# and JavaScript: A Comparative Guide</h1>
                </section>
                <p className='date-label'>Date: 2024/Sept/23 </p>
                
                <section>
                    <h2>The Evolution of OOP</h2>
                    <p>The roots of OOP can be traced back to the early days of programming when developers sought to create more structured and reusable code. Over time, OOP has become a fundamental paradigm in modern programming, allowing for better data management and code organization. As applications grew in complexity, so did the need for effective OOP patterns to manage object creation, relationships, and behaviors.</p>
                </section>

                <section>
                    <h2>Key OOP Principles</h2>

                    <h3>Classes and Objects</h3>
                    <p> Classes serve as blueprints for creating objects, encapsulating properties and behaviors. Both C# and JavaScript support class-based OOP, but their syntax and implementations differ. For instance, a Province class can be implemented as follows:</p>
                    
                    <h4>C# Example: Canadian Provinces</h4>
                    <pre>
                        <code>
{`public class Province
{
    public string Name { get; set; }
    public int Population { get; set; }
    public string Capital { get; set; }

    public void DisplayInfo()
    {
        Console.WriteLine(\`Province: \${Name}, Population: \${Population}, Capital: \${Capital}\`);
    }
}`}
                        </code>
                    </pre>
                    
                    <h4>JavaScript Example: Canadian Provinces</h4>
                    <pre>
                        <code>
{`class Province {
    constructor(name, population, capital) {
        this.name = name;
        this.population = population;
        this.capital = capital;
    }

    displayInfo() {
        console.log(\`Province: \${this.name}, Population: \${this.population}, Capital: \${this.capital}\`);
    }
}`}
                        </code>
                    </pre>

                    <h3>Encapsulation</h3>
                    <p>This principle protects object data by restricting access to certain properties. C# uses access modifiers like private and public, while JavaScript uses closures or private fields.</p>

                    <h4>C# Example: Canadian Currency</h4>
                    <pre>
                        <code>
{`public class CanadianDollar
{
    private decimal amount;

    public void Deposit(decimal value)
    {
        amount += value;
    }

    public decimal GetAmount()
    {
        return amount;
    }
}`}
                        </code>
                    </pre>
                    
                    <h4>JavaScript Example: Canadian Currency</h4>
                    <pre>
                        <code>
{`class CanadianDollar {
    #amount = 0;

    deposit(value) {
        this.#amount += value;
    }

    getAmount() {
        return this.#amount;
    }
}`}
                        </code>
                    </pre>

                    <h3>Inheritance</h3>
                    <p>This allows a class to inherit properties and methods from another class. C# and JavaScript both support inheritance, but C# uses the : base syntax while JavaScript employs extends.</p>
                    
                    <h4>C# Example: Canadian Wildlife</h4>
                    <pre>
                        <code>
{`public class Animal
{
    public void Eat()
    {
        Console.WriteLine("Animal is eating.");
    }
}

public class Beaver : Animal
{
    public void BuildDam()
    {
        Console.WriteLine("Beaver is building a dam.");
    }
}`}
                        </code>
                    </pre>
                    
                    <h4>JavaScript Example: Canadian Wildlife</h4>
                    <pre>
                        <code>
{`class Animal {
    eat() {
        console.log("Animal is eating.");
    }
}

class Beaver extends Animal {
    buildDam() {
        console.log("Beaver is building a dam.");
    }
}`}
                        </code>
                    </pre>

                    <h3>Polymorphism</h3>
                    <p>This concept allows methods to behave differently based on the object that invokes them. Both languages support polymorphism through method overriding.</p>
                    
                    <h4>C# Example: Canadian Sports</h4>
                    <pre>
                        <code>
{`public class Sport
{
    public virtual void Play()
    {
        Console.WriteLine("Playing a sport.");
    }
}

public class IceHockey : Sport
{
    public override void Play()
    {
        Console.WriteLine("Playing ice hockey.");
    }
}`}
                        </code>
                    </pre>
                    
                    <h4>JavaScript Example: Canadian Sports</h4>
                    <pre>
                        <code>
{`class Sport {
    play() {
        console.log("Playing a sport.");
    }
}

class IceHockey extends Sport {
    play() {
        console.log("Playing ice hockey.");
    }
}`}
                        </code>
                    </pre>

                    <h3>Abstraction</h3>
                    <p> Abstraction focuses on exposing only the necessary details of an object while hiding complex implementation details. C# has abstract classes and interfaces, while JavaScript uses a combination of classes and methods.</p>
                    
                    <h4>C# Example: Canadian Transportation</h4>
                    <pre>
                        <code>
{`public abstract class Transportation
{
    public abstract void Move();
}

public class Car : Transportation
{
    public override void Move()
    {
        Console.WriteLine("The car is driving.");
    }
}`}
                        </code>
                    </pre>
                    
                    <h4>JavaScript Example: Canadian Transportation</h4>
                    <pre>
                        <code>
{`class Transportation {
    move() {
        throw new Error("You must implement move() in a subclass.");
    }
}

class Car extends Transportation {
    move() {
        console.log("The car is driving.");
    }
}`}
                        </code>
                    </pre>
                </section>

                <section>
                    <h2>Practical Comparison: Building a Canadian Travel App</h2>
                    <p>Let's consider a practical example of a travel app that showcases Canadian destinations. This app could allow users to explore different provinces, learn about wildlife, and discover sports activities.</p>
                    
                    <h4>C# Implementation:</h4>
                    <pre>
                        <code>
{`public class TravelApp
{
    public void Explore(Province province)
    {
        province.DisplayInfo();
        var beaver = new Beaver();
        beaver.BuildDam();
        var iceHockey = new IceHockey();
        iceHockey.Play();
    }
}`}
                        </code>
                    </pre>
                    
                    <h4>JavaScript Implementation:</h4>
                    <pre>
                        <code>
{`class TravelApp {
    explore(province) {
        province.displayInfo();
        const beaver = new Beaver();
        beaver.buildDam();
        const iceHockey = new IceHockey();
        iceHockey.play();
    }
}`}
                        </code>
                    </pre>
                </section>

                <section>
                    <h2>Why OOP Matters for Developers</h2>
                    <p>Understanding OOP is vital for developers in today’s programming landscape. It promotes code reusability, scalability, and maintainability, which are essential for developing robust applications. Whether you’re working in a .NET environment with C# or developing dynamic web applications with JavaScript, these principles empower you to build software that can adapt and grow over time.</p>
                </section>

                <section>
                    <h2>Final Thoughts</h2>
                    <p>By grasping the fundamentals of OOP in C# and JavaScript, you equip yourself with the tools to create effective and flexible software solutions. The Canadian-themed examples not only make the concepts relatable but also demonstrate their practical utility in real-world applications.</p>
                </section>

            </article>
        </div>
        </section>
  );
}

export default Article01;
